import Vue from 'vue'
import VueMeta from 'vue-meta'
import PortalVue from 'portal-vue'
import { plugin as InertiaPlugin, App as InertiaApp } from '@inertiajs/inertia-vue'
import { InertiaProgress } from '@inertiajs/progress/src'
import VueTailwind from 'vue-tailwind'
import VueTailwindSettings from './Config/VueTailwind'
import LoadScript from 'vue-plugin-load-script'

Vue.config.productionTip = false
Vue.mixin({ methods: { route: window.route } })
Vue.use(InertiaPlugin)
Vue.use(PortalVue)
Vue.use(VueMeta)
Vue.use(LoadScript)

Vue.use(VueTailwind, VueTailwindSettings)

InertiaProgress.init({
  showSpinner: true,
})

let app = document.getElementById('app')

new Vue({
  metaInfo: {
    titleTemplate: (title) => title ? `${title} - SVV` : 'Sběr vědeckých výsledků'
  },
  render: h => h(InertiaApp, {
    props: {
      initialPage: JSON.parse(app.dataset.page),
      resolveComponent: name => import(`@/Pages/${name}`).then(module => module.default),
    },
  }),
}).$mount(app)
